import React from 'react'
import { connect } from 'react-redux'
import { addMovimentoEstoqueLote } from 'store/produto/actions'
import Loading from 'components/loading'
import TelaDeErro from 'components/telaDeErro'
import { BtnSalvar, BtnSalvarVoltar } from 'components/Botao'
import { isArrayNotEmpty } from 'util/utils'
import { Container, CardDeck } from 'react-bootstrap'
import SearchBar from 'components/search-bar'
import { getProdutosFiltrados } from 'util/importar-produtos-lote'
import BoxProduto from 'components/produto/boxProdutoEstoque'
import { push } from 'connected-react-router'
import { ExcelButtons } from 'components/estoque/ExcelButtons'
import dayjs from 'dayjs'
import 'styles/produto.css'
import { toSafeInteger } from 'lodash'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

const searchOptions = {
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.4,
  keys: [
    { name: 'nomeProduto', weight: 2 },
    { name: 'descricaoProduto', weight: 0.6 },
    { name: 'categoriaEntity.nomeCategoria', weight: 0.4 }
  ]
}

class TelaEstoque extends React.Component {
  constructor (props) {
    super(props)
    const { produtos } = this.props
    this.elementoTopo = React.createRef()

    this.state = {
      produtosComEstoque: getProdutosFiltrados(produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoque: getProdutosFiltrados(produtos.filter((x) => x.estoque <= 0)),
      produtosComEstoqueFiltrados: getProdutosFiltrados(produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoqueFiltrados: getProdutosFiltrados(produtos.filter((x) => x.estoque <= 0)),
      produtosAlterados: {},
      categoria: {},
      hasSearch: false,
      subCategoria: {},
      inputFileFieldRef: Math.random().toString(36),
      estagio: 0,
      expireDays: 0,
      hasChanges: true,
      isLoading: false,
      apiError: null
    }

    this.displayProdutosList = this.displayProdutosList.bind(this)
  }

  resetApiError = () => {
    this.setState({ apiError: null })
  }

  componentDidUpdate (prevProps, prevState) {
    const { produtos, isEstoqueLotePosting } = this.props
    if (produtos !== prevProps.produtos) {
      this.setProdutosFiltrados()
    }

    const estagio1Concluido = prevProps.isEstoqueLotePosting && !isEstoqueLotePosting
    if (estagio1Concluido) {
      this.setState({
        estagio: 0,
        produtosComEstoque: [],
        produtosSemEstoque: [],
        produtosAlterados: {},
        isLoading: false
      })
    }
  }

  handleEdit = (produto) => {
    const { dispatch } = this.props
    localStorage.removeItem('produto')
    localStorage.setItem('produto', JSON.stringify(produto))
    setTimeout(() => {
      dispatch(push(`/produtos/edit/${produto.idProduto}`))
    }, 10)
  }

  displayProdutosList (produtos, onChange, listIdentifier) {
    if (isArrayNotEmpty(produtos)) {
      return produtos.map((produto, i) => {
        const hasChange = this.state.produtosAlterados[produto.idProduto]
        if (hasChange) {
          produto = { ...hasChange, ...produto }
        }
        const uniqueKey = `${produto.idProduto}_${listIdentifier}`
        return <BoxProduto key={uniqueKey} produto={produto} i={i} onChange={onChange} onEdit={this.handleEdit} hide={this.state.estagio !== 0} />
      })
    } else {
      return <>Não existem produtos na categoria selecionada</>
    }
  }

  getProdutosFiltrados = (produtos) => getProdutosFiltrados(produtos, this.props.categorias, this.state.categoria, this.state.subCategoria)

  setProdutosFiltrados = () =>
    this.setState({
      produtosComEstoque: this.getProdutosFiltrados(this.props.produtos.filter((x) => x.estoque > 0)),
      produtosSemEstoque: this.getProdutosFiltrados(this.props.produtos.filter((x) => x.estoque <= 0))
    })

  handleUpdateProduto = (dados, callback) => {
    const { produtos } = this.props
    const currentProdutosAlterados = this.state.produtosAlterados
    const produtoAlterado = produtos.filter((el) => el.idProduto === dados.idProduto || (el.codigoInterno && el.codigoInterno === dados.codigoInterno))[0]
    if (produtoAlterado) {
      currentProdutosAlterados[produtoAlterado.idProduto] = {
        ...produtoAlterado,
        ...dados
      }
      this.setState(
        {
          produtosAlterados: { ...currentProdutosAlterados }
        },
        callback
      )
    }
  }

  hasInvalidItens = () =>
    !Object.keys(this.state.produtosAlterados).length || Object.keys(this.state.produtosAlterados).some((x) => this.state.produtosAlterados[x].invalid === true)

  botaoSalvar = () => <BtnSalvar onSalvar={() => this.setState({ estagio: 1 })} disabled={this.hasInvalidItens()} />

  estagioAdicionarAlteracoes = () => {
    const { produtosComEstoqueFiltrados, produtosSemEstoqueFiltrados, expireDays } = this.state
    const { isCatalogo } = this.props

    const changeItems = async ({ result, query }) => {
      this.setState({ isLoading: true })

      try {
        this.setState({
          hasSearch: !!query,
          produtosComEstoque: result.filter((x) => x.estoque > 0),
          produtosComEstoqueFiltrados: result.filter((x) => x.estoque > 0),
          produtosSemEstoque: result.filter((x) => x.estoque <= 0),
          produtosSemEstoqueFiltrados: result.filter((x) => x.estoque <= 0),
          apiError: null
        })
      } catch (error) {
        this.setState({
          apiError: [
            {
              campo: 'Mudança de Itens',
              mensagem: error.message || 'Ocorreu um erro ao mudar os itens.'
            }
          ]
        })
      } finally {
        this.setState({ isLoading: false })
      }
    }

    const onImport = ({ alteracoesComEstoque, alteracoesSemEstoque, quantidadeAlteracoes }) => {
      try {
        if (quantidadeAlteracoes > 0) {
          this.setState({
            estagio: 1,
            produtosAlterados: [...alteracoesComEstoque, ...alteracoesSemEstoque],
            hasChanges: true
          })
        } else {
          this.setState({
            estagio: 1,
            produtosAlterados: [...alteracoesComEstoque, ...alteracoesSemEstoque],
            hasChanges: false
          })
        }
      } catch (error) {
        this.setState({
          apiError: [
            {
              campo: 'Importação de Produtos',
              mensagem: 'Ocorreu um erro ao importar produtos.'
            }
          ]
        })
      }
    }

    const ButtonsExcel = ({ produtosComEstoque, produtosSemEstoque }) => {
      const importar = ({ alteracoesComEstoque, alteracoesSemEstoque, quantidadeAlteracoes }) => {
        onImport({
          alteracoesComEstoque,
          alteracoesSemEstoque,
          quantidadeAlteracoes
        })
      }
      return <ExcelButtons produtosComEstoque={produtosComEstoque} produtosSemEstoque={produtosSemEstoque} onImport={importar} isCatalogo={false} />
    }

    const setStateFiltro = (diasVencimento, produtosComEstoque, produtosSemEstoque) =>
      this.setState({
        expireDays: diasVencimento,
        produtosComEstoqueFiltrados: produtosComEstoque,
        produtosSemEstoqueFiltrados: produtosSemEstoque,
        apiError: null
      })

    const removerFiltro = () => {
      const { produtosComEstoque, produtosSemEstoque } = this.state
      setStateFiltro(0, produtosComEstoque, produtosSemEstoque)
    }

    const isAfter = (vencimento, limite) => vencimento && dayjs(vencimento).isAfter(limite)
    const isBefore = (vencimento, limite) => vencimento && dayjs(vencimento).isBetween(dayjs(), limite, 'day', '[]')
    const filtrarProdutosAfter = (produtos, limite) => produtos.filter((p) => isAfter(p.vencimento, limite))
    const filtrarProdutosBefore = (produtos, limite) => produtos.filter((p) => isBefore(p.vencimento, limite))

    const filtrarProdutosAposLimite = (dias) => {
      const { produtosComEstoque, produtosSemEstoque } = this.state
      const limite = dayjs().add(dias - 1, 'day')

      setStateFiltro(dias, filtrarProdutosAfter(produtosComEstoque, limite), filtrarProdutosAfter(produtosSemEstoque, limite))
    }

    const filtrarProdutosAntesLimite = (dias) => {
      const { produtosComEstoque, produtosSemEstoque } = this.state
      const limite = dayjs().add(Math.abs(dias), 'day')

      setStateFiltro(dias, filtrarProdutosBefore(produtosComEstoque, limite), filtrarProdutosBefore(produtosSemEstoque, limite))
    }

    const filtrarProduto = (event) => {
      this.setState({ isLoading: true })

      try {
        const diasVencimento = toSafeInteger(event.target.value)
        if (diasVencimento === 0) removerFiltro()
        else if (diasVencimento > 0) filtrarProdutosAposLimite(diasVencimento)
        else filtrarProdutosAntesLimite(diasVencimento)
      } catch (error) {
        console.error(error)
        this.setState({
          apiError: [
            {
              campo: 'Filtro por Vencimento',
              mensagem: error.message || 'Ocorreu um erro ao filtrar produtos por vencimento.'
            }
          ]
        })
      } finally {
        this.setState({ isLoading: false })
      }
    }

    const Filter = () => {
      return (
        <div className='d-flex align-items-center mt-3 pl-1'>
          <div>Vence em:</div>
          <div className='ml-2'>
            <select onChange={filtrarProduto} className='form-control' value={expireDays}>
              <option value='0'>Sem Limite</option>
              <option value='7'>Mais de 7 dias</option>
              <option value='15'>Mais de 15 dias</option>
              <option value='30'>Mais de 30 dias</option>
              <option value='60'>Mais de 60 dias</option>
              <option value='-7'>Menos de 7 dias</option>
              <option value='-15'>Menos de 15 dias</option>
              <option value='-30'>Menos de 30 dias</option>
              <option value='-60'>Menos de 60 dias</option>
            </select>
          </div>
        </div>
      )
    }
    return (
      <div>
        <ButtonsExcel produtosComEstoque={produtosComEstoqueFiltrados} produtosSemEstoque={produtosSemEstoqueFiltrados} isCatalogo={isCatalogo} />
        <SearchBar hasSearch={this.state.hasSearch} items={this.props.produtos} options={searchOptions} placeholder='Procurar em estoque' onSearchItems={changeItems} />
        <Filter />
        <hr />
        <h6 className='mb-3'>Produtos com Estoque:</h6>
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosComEstoqueFiltrados, this.handleUpdateProduto, 'comEstoque')}</CardDeck>
        <hr />
        <h6 className='mb-3'>Produtos sem Estoque:</h6>
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosSemEstoqueFiltrados, this.handleUpdateProduto, 'semEstoque')}</CardDeck>
      </div>
    )
  }

  estagioValidarAlteracoes = () => {
    const { dispatch } = this.props
    const { produtosAlterados } = this.state
    const payload = produtosAlterados.map((produto) => {
      return {
        unidade: produto.unidadeBase,
        preco: produto.precoBase,
        idProduto: produto.idProduto,
        idLote: produto.idLote,
        motivo: 'teste',
        qrCode: produto.qrCode,
        numLote: produto.numLote,
        codigoInterno: produto.codigoInterno,
        validade: produto.vencimento ? dayjs(produto.vencimento).format('YYYY-MM-DDTHH:mm') : undefined,
        tipo: 'substituir',
        quantidade: produto.quantidade,
        nomeProduto: produto.nomeProduto
      }
    })

    return (
      <Container>
        <h5>Produtos Alterados</h5>
        <h6>Verifique se as alterações estão corretas antes de salvar</h6>
        <hr />
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosAlterados, this.handleUpdateProduto, 'alterados')}</CardDeck>
        <BtnSalvarVoltar
          onVoltar={() => this.setState({ estagio: 0 })}
          onSalvar={() => dispatch(addMovimentoEstoqueLote(payload, 'Upload de planilha manual'))}
          disabled={this.hasInvalidItens()}
        />
      </Container>
    )
  }

  render () {
    const { isEstoqueLotePosting } = this.props
    const { isLoading, apiError } = this.state

    if (isEstoqueLotePosting || isLoading) {
      return <Loading />
    }

    if (apiError) {
      return <TelaDeErro error={apiError} callbackReturn={this.resetApiError} />
    }

    return (
      <div ref={this.elementoTopo}>
        {this.state.estagio === 0 ? this.estagioAdicionarAlteracoes() : null}
        {this.state.estagio === 1 ? this.estagioValidarAlteracoes() : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isEstoqueLotePosting: state.produto.isEstoqueLotePosting
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TelaEstoque)
